import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "./register.css";
import Header from "../components/Header";
import RegisterForm from "../components/RegisterForm";
import FlingInfo from "../components/FlingInfo";
import { appConfig } from "../config.js";
const NODEURL = appConfig.NODEURL;

export default function Register() {
  const location = useLocation();
  let { cartItems } = location.state || {};
  return (
    <Header title="Fling Registration">
      <div
        id="topofpage"
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 20,
          // width: 260,
          margin: "auto",
        }}
        className="App"
      >
        <FlingInfo />
        <RegisterForm cartItems={cartItems} />
      </div>
    </Header>
  );
}

import React, { useState, useEffect, useRef } from "react";

import Header from "../components/Header";
import MuiSize from "../components/MuiSize";
import "../App.css";
import Box from "@mui/material/Box";
import {
  // createTheme,
  useTheme,
  styled,
  // ThemeProvider,
} from "@mui/material/styles";

import "./test.css";

export default function Test() {
  const theme = useTheme();

  const FibonacciSequence = () => {
    // Function to generate Fibonacci sequence up to a specified level
    const generateFibonacci = (levels) => {
      let sequence = [0, 1];
      for (let i = 2; i < levels; i++) {
        sequence.push(sequence[i - 1] + sequence[i - 2]);
      }
      return sequence;
    };

    // Generate the Fibonacci sequence up to 5 levels
    const fibonacciSequence = generateFibonacci(8);

    return (
      <div>
        {fibonacciSequence.map((num, index) => {
          console.log(num);
          return (
            <div
              style={{
                width: num * 100,
                backgroundColor: theme.palette.grey[100],
              }}
              key={index}
            >
              Level {index + 1}: {num}
            </div>
          );
        })}
      </div>
    );
  };

  <MuiSize />;
  const pail = ["primary", "secondary", "warning", "info", "error", "success"];
  return (
    <div>
      <Header>
        <FibonacciSequence />
        <MuiSize />
        {/* <div style={{ position: "relative" }}>
          <div style={{ position: "absolute", left: 0, top: 0 }}>
            Opaque Text
          </div>
          <div style={{ position: "absolute", left: 0, top: 0 }}>
            This is opaque
          </div>
        </div> */}
        <div
          style={{
            display: "flex",
            gap: 5,
            width: "100%",
            border: "1px solid black",
          }}
        >
          {pail.map((p, index) => {
            return (
              <div
                key={index}
                style={{
                  flex: 1,
                  display: "flex",
                  flexDirection: "column",
                  gap: 5,
                }}
              >
                {p} {theme.palette[p].main}
                {["light", "main", "dark"].map((shade, index) => {
                  return (
                    <div
                      key={index}
                      style={{
                        padding: 10,
                        backgroundColor: theme.palette[p][shade],
                        color: theme.palette[p].contrastText,
                      }}
                    >
                      {shade} {theme.palette[p][shade]}
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>

        <pre style={{ display: "block" }}>{JSON.stringify(theme, null, 3)}</pre>
        <div style={{ display: "none" }}>
          <div
            style={{
              flex: 1,
              backgroundColor: theme.palette.primary.main,
              color: theme.palette.primary.contrastText,
            }}
          >
            Primary:
            {theme.palette.primary.main}
          </div>

          <div
            style={{
              flex: 1,
              backgroundColor: theme.palette.secondary.main,
              color: theme.palette.secondary.contrastText,
            }}
          >
            Secondary
            {theme.palette.secondary.main}
          </div>
        </div>
        <a href="https://www.giveforgoodlouisville.org/organization/louisville-country-dancers">
          Give For Good
        </a>
      </Header>
    </div>
  );
}

import React from "react";
import {
  BrowserRouter as Router,
  Routes, // instead of "Switch"
  Route,
} from "react-router-dom";

import Dancers from "./pages/Dancers";
import Register from "./pages/Register";
import SurveyResults from "./pages/SurveyResults";
import Survey from "./pages/Survey";
import FlingPage from "./pages/FlingPage";
import Test from "./pages/Test";

import UserAgreement from "./pages/UserAgreement";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Schedule2024 from "./pages/Schedule2024";
import Schedule from "./pages/Schedule";
import PpCheckout from "./pages/PpCheckout";
import PayPalReturn from "./pages/PayPalReturn";
import PayPalAltCheckOut from "./pages/PayPalAltCheckOut";

import MuiSize from "./components/MuiSize";

import "./App.css";

import Theme from "./Theme";

export default function App() {
  return (
    <Router>
      <Theme>
        <MuiSize />
        <Routes>
          <Route path="/" element={<Register />} />
          <Route path="/dancers" element={<Dancers />} />
          <Route path="/surveyresults" element={<SurveyResults />} />
          <Route path="/survey" element={<Survey />} />
          <Route path="/fling" element={<FlingPage />} />
          <Route path="/test" element={<Test />} />
          <Route path="/user-agreement" element={<UserAgreement />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/schedule2024" element={<Schedule2024 />} />
          <Route path="/schedule" element={<Schedule />} />
          <Route path="/checkout" element={<PpCheckout />} />
          <Route path="/return" element={<PayPalReturn />} />
          <Route path="/altcheckout" element={<PayPalAltCheckOut />} />
        </Routes>
      </Theme>
    </Router>
  );
}

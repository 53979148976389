import React from "react";
import { useTheme } from "@mui/material/styles";

import {
  // TextField,
  // Button,
  Box,
  // Checkbox,
  // FormControlLabel,
  // InputLabel,
  // IconButton,
  // Select,
  // MenuItem,
} from "@mui/material";

const ContactInfo = () => {
  const theme = useTheme();
  return (
    <Box>
      <div
        style={{
          ...theme.typography.body1,
          backgroundColor: theme.palette.grey[100],
          padding: 5,
          borderRadius: 5,
          margin: 20,
        }}
      >
        If you need help, if anything is not working, email us at
        registrar@louisvillecountrydancers.org, or message to 4138137109
      </div>
    </Box>
  );
};

export default ContactInfo;

import React, { useState, useEffect, useRef } from "react";
import {
  TextField,
  Button,
  Box,
  Checkbox,
  FormControlLabel,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
// import { CSVLink } from "react-csv";
import {
  createTheme,
  useTheme,
  styled,
  alpha,
  ThemeProvider,
} from "@mui/material/styles";

import InputBase from "@mui/material/InputBase";
import CloseIcon from "@mui/icons-material/Close";
import "./schedule.css";

import Header from "../components/Header";

const Schedule = () => {
  const theme = useTheme();

  const StyledInfo = styled("div")({
    fontFamily: "adobe-garamond-pro",
    backgroundColor: theme.palette.info.main,
    color: theme.palette.info.contrastText,
    boxShadow: theme.shadows[5],
    borderRadius: 10,
    maxWidth: 400,
    minWidth: 250,
    height: "auto",
    margin: "auto",
    padding: 6,
    display: "flex",
    flexDirection: "column",
    fontSize: "1.2em",
    lineHeight: "1.4em",
    gap: 20,
  });

  return (
    <div style={{}}>
      <div
        style={{
          ...theme.typography.h4,
          fontWeight: 800,
          margin: "auto",
          marginBottom: 10,
          width: "fit-content",
          backgroundColor: theme.palette.secondary.main,
          color: theme.palette.secondary.contrastText,
          borderRadius: 10,
          padding: 10,
          boxShadow: theme.shadows[10],
        }}
      >
        Fleur de Lis Fling 2024 Daily Schedule
      </div>
      <div style={{ display: "flex", flexWrap: "wrap", gap: 20 }}>
        <StyledInfo>
          <div className="title">Notes</div>
          <div>
            Our callers will alternate who calls Gents and Ladies or Larks and
            Robins each night.
          </div>
          <div>Both terms may be used all weekend.</div>
          <div>Scroll to the bottom for addresses of all locations.</div>
        </StyledInfo>

        <StyledInfo className="schedule-section">
          <div className="title">Friday</div>
          <div className="column">
            <div className="row">
              <div className="time">7:00 PM</div>
              <div className="event">Doors Open</div>
            </div>
            <div className="row">
              <div className="time">7:15–7:45 PM</div>
              <div className="event">Waltzing with Double Trouble</div>
            </div>
            <div className="row">
              <div className="time">8:00–9:30 PM</div>
              <div className="event">
                Contra with River Rainbowface and Double Trouble
              </div>
            </div>
            <div className="row">
              <div className="time">10:00–11:30 PM</div>
              <div className="event">
                Contra with Bob Isaacs and Electric EEL
              </div>
            </div>
          </div>
        </StyledInfo>

        <StyledInfo className="schedule-section">
          <div className="title">Saturday Dance Workshops</div>
          <div className="column">
            <div className="row">
              <div className="time">10:00–11:15 AM</div>
              <div className="event">
                Square Dance with Bob Isaacs and Musicians
              </div>
            </div>
            <div className="row">
              <div className="time">11:30 AM–1:00 PM</div>
              <div className="event">
                Role-Swapping Mid-Dance with River Rainbowface and Double
                Trouble
              </div>
            </div>
            <div className="row">
              <div className="time">1:00–2:30 PM</div>
              <div className="event">Lunch</div>
            </div>
            <div className="row">
              <div className="time">2:30–3:45 PM</div>
              <div className="event">
                Waltz Workshop with Ed Howe and Tami Dahl
              </div>
            </div>
            <div className="row">
              <div className="time">4:00–5:30 PM</div>
              <div className="event">
                Contra Workshop with Bob Isaacs and Electric EEL
              </div>
            </div>
          </div>
        </StyledInfo>

        <StyledInfo className="schedule-section">
          <div className="title">Saturday Music/Singing Workshops</div>
          <div className="column">
            <div className="row">
              <div className="time">10:00–11:15 AM</div>
              <div className="event">Singing with Callie Allison</div>
            </div>
            <div className="row">
              <div className="time">11:30 AM–1:00 PM</div>
              <div className="event">Musicians' Workshop with Emily Troll</div>
            </div>
            <div className="row">
              <div className="time">1:00–2:30 PM</div>
              <div className="event">Lunch</div>
            </div>
            <div className="row">
              <div className="time">2:30–3:45 PM</div>
              <div className="event">Rounds Singing with River Rainbowface</div>
            </div>
            <div className="row">
              <div className="time">4:00–5:30 PM</div>
              <div className="event">
                Musicians' Jam Led by Local Band Leaders
              </div>
            </div>
          </div>
        </StyledInfo>

        <StyledInfo className="schedule-section">
          <div className="title">Saturday Night</div>
          <div className="column">
            <div className="row">
              <div className="time">7:00 PM</div>
              <div className="event">Doors Open</div>
            </div>
            <div className="row">
              <div className="time">7:15–7:45 PM</div>
              <div className="event">Waltzing with Electric EEL</div>
            </div>
            <div className="row">
              <div className="time">8:00–9:15 PM</div>
              <div className="event">
                Contra with River Rainbowface and Electric EEL
              </div>
            </div>
            <div className="row">
              <div className="time">9:45–11:00 PM</div>
              <div className="event">
                Contra with Bob Isaacs and Double Trouble
              </div>
            </div>
          </div>
        </StyledInfo>

        <StyledInfo className="schedule-section">
          <div className="title">Sunday</div>
          <div className="column">
            <div className="row">
              <div className="time">10:30–11:30 AM</div>
              <div className="event">Waltzing with Electric EEL</div>
            </div>
            <div className="row">
              <div className="time">11:30 AM–1:30 PM</div>
              <div className="event">Brunch</div>
            </div>
            <div className="row">
              <div className="time">1:30–2:25 PM</div>
              <div className="event">
                Contra with Bob Isaacs and Double Trouble
              </div>
            </div>
            <div className="row">
              <div className="time">2:35–3:30 PM</div>
              <div className="event">
                Contra with River Rainbowface and Electric EEL
              </div>
            </div>
          </div>
        </StyledInfo>

        <div>
          <StyledInfo style={{ marginBottom: 20 }} className="schedule-section">
            <div className="title">Locations</div>
          </StyledInfo>

          <StyledInfo className="schedule-section">
            <div className="location-section">
              <div className="title">Main Dance Hall:</div>
              <div>Highland Community Ministries</div>
              <div>1228 E Breckinridge Street</div>
              <div>Louisville, KY 40204</div>
            </div>
          </StyledInfo>
        </div>

        {/* hidden for now: */}
        <StyledInfo style={{ display: "none" }} className="schedule-section">
          <div className="location-section">
            <div className="title">Blous After Party:</div>
            <div>The Ambo Dance Theater</div>
            <div>812 Clarks Lane, Louisville, KY 40217</div>
            <div>Snacks available, $5 donation suggested.</div>
          </div>
        </StyledInfo>

        <StyledInfo className="schedule-section">
          <div className="location-section">
            <div className="title">Ken and Pam’s After Party:</div>
            <div>1630 Lucia Avenue, Louisville, KY 40204</div>
            <div>Map for suggested parking available on a separate sheet.</div>
            <div>Vegetarian snacks and non-alcoholic drinks available.</div>
            <div>
              Feel free to bring your favorite late-night beverage or snack.
            </div>
          </div>
        </StyledInfo>

        <StyledInfo className="schedule-section">
          <div id="sundaylocation" className="location-section">
            <div className="title">Sunday Morning Location:</div>
            <div>Louisville Dance Alliance</div>
            <div>845 Barret Ave, Louisville, KY 40204</div>
          </div>
        </StyledInfo>

        <StyledInfo className="schedule-section">
          <div className="location-section">
            <div className="title">Doug and Angela’s After Party:</div>
            <div>9797 N Skyline Drive, Floyds Knobs, IN 47119</div>
            <div>
              Soup, Chili, and Toasted Cheese will be provided by the hosts.
            </div>
            <div>Please bring food to share.</div>
          </div>
        </StyledInfo>
      </div>
    </div>
  );
};

export default Schedule;
